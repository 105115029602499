import { useState } from "react";
import { withTranslation } from "react-i18next";
import classes from "../../assets/css/components/base/Sections.module.scss";
import dropDownAccountMenuIcon from "../../assets/icons/dropDownAccountMenuIcon.svg";

function Sections(props) {
  const [hide, setHide] = useState(false);

  return (
    <div
      className={`${props.together ? "" : "mt-7"}
         ${
           props.className
         } flex w-full mobile:px-5 py-4 justify-center items-start flex-col mobile:m-0 mobile:mb-8 smallMobile:w-full mobile:w-full ml-5 `}
    >
      <div className="bg-[#191d33ff] p-4 w-full mobile:p-0">
        <div
          className={`${classes.sectionHeader} text-white w-full py-2 px-[22px] flex max-h-[35px] justify-between`}
        >
          <span>{props.title}</span>
          <span
            onClick={() => setHide(!hide)}
            className="focus:outline-none items-center self-center cursor-pointer"
          >
            <img
              src={dropDownAccountMenuIcon}
              alt="dropDownAccountMenuIcon"
              className={hide ? "" : "rotate-180"}
            />
          </span>
        </div>
        {!hide && <div className="w-full">{props.children}</div>}
      </div>
      {props.button && (
        <div
          className={`flex px-5 justify-end items-end flex-col mobile:m-3 mobile:p-4 mobile:mb-8 smallMobile:w-full mobile:w-full w-full mt-[32px] ml-5 rounded-lg ${
            hide && !props.together ? "hidden" : ""
          }`}
        >
          {props.button}
        </div>
      )}
    </div>
  );
}

export default withTranslation()(Sections);
