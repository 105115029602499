// components/errors/KeycloakError.jsx
import React from "react";
import { withTranslation } from "react-i18next";
import Button from "@/components/laigo/Button";
import Sections from "@/components/base/Sections";

const KeycloakError = ({ error, t }) => {
  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="w-full max-w-4xl px-4">
        <Sections title={t("Connection_Error")}>
          <div className="lg:px-[22px] py-4 w-full mobile:pr-9 mobile:pl-7">
            <div className="flex flex-col space-y-4">
              <p className="text-[#e6e6e6ff]">
                {t("Keycloak_Connection_Error_Message")}
              </p>

              <ul className="list-disc list-inside space-y-2 text-[#e6e6e6ff]">
                <li>{t("Check_Internet_Connection")}</li>
                <li>{t("Verify_Auth_Server")}</li>
                <li>{t("Refresh_Page")}</li>
              </ul>

              {error?.message && (
                <p className="text-sm text-[#8b8b8b]">
                  {t("Error_Details")}: {error.message}
                </p>
              )}

              <div className="flex justify-start mt-4">
                <Button
                  text={t("Retry_Connection")}
                  onClick={() => window.location.reload()}
                  className="secondaryBtn"
                />
              </div>
            </div>
          </div>
        </Sections>
      </div>
    </div>
  );
};

export default withTranslation()(KeycloakError);
