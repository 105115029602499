import Keycloak from "keycloak-js";
import store from "../store/store";
import { login, updateRefreshToken } from "../store/AuthSlice";
import { initOptions } from "../KeycloakConfig";
import i18n from "../i18n";

const _kc = new Keycloak(initOptions);

const initKeycloak = (onAuthenticatedCallback) => {
  const isPublicRoute = () => {
    const publicRoutes = ["/register"];
    return publicRoutes.some((route) =>
      window.location.pathname.includes(route)
    );
  };

  if (isPublicRoute()) {
    onAuthenticatedCallback();
    return;
  }

  _kc
    .init({
      onLoad: "login-required",
      silentCheckSsoRedirectUri:
        window.location.origin + "/silent-check-sso.html",
      pkceMethod: "S256",
      checkLoginIframe: false,
    })
    .then((authenticated) => {
      if (authenticated) {
        store.dispatch(login(_kc));
        applyKeycloakLanguageSetting();
        setupTokenRefresh();
      }
      onAuthenticatedCallback();
    })
    .catch((error) => {
      console.error("Keycloak initialization failed", error);
      onAuthenticatedCallback({ error });
    });
};

const setupTokenRefresh = () => {
  setInterval(() => {
    if (isLoggedIn()) {
      _kc
        .updateToken(70)
        .then((refreshed) => {
          if (refreshed) {
            const payload = {
              token: _kc.token,
              idToken: _kc.idToken,
              tokenExpiresTime: _kc.tokenParsed.exp,
              tokenIssuedTime: _kc.tokenParsed.iat,
              tokenExpiryPeriod: _kc.tokenParsed.exp - _kc.tokenParsed.iat,
            };
            store.dispatch(updateRefreshToken(payload));
          }
        })
        .catch(() => {
          console.error("Failed to refresh token");
          doLogin();
        });
    }
  }, window.tokenExpireTime * 1000);
};

function applyKeycloakLanguageSetting() {
  const languageChangedManually =
    localStorage.getItem("languageChangedManually") === "true";
  const userSelectedLanguage = localStorage.getItem("userSelectedLanguage");
  const keycloakLanguage = _kc.tokenParsed?.locale || "en";

  if (!languageChangedManually) {
    // If the language wasn't changed manually, apply the Keycloak language setting
    if (!userSelectedLanguage || userSelectedLanguage !== keycloakLanguage) {
      i18n.changeLanguage(keycloakLanguage);
      localStorage.setItem("userSelectedLanguage", keycloakLanguage);
      localStorage.setItem("languageChangedManually", "false"); // Ensure flag is correct
    }
  } else {
    i18n.changeLanguage(userSelectedLanguage);
  }
}

const doLogin = () => {
  _kc
    .login({
      scope: "openid",
      promiseType: "native",
    })
    .catch((error) => {
      console.error("Login failed", error);
    });
};

const doLogout = () => {
  return _kc.logout({ redirectUri: window.location.href });
};

const getToken = () => _kc.token;

const getTokenParsed = () => _kc.tokenParsed;

const isLoggedIn = () => !!_kc.token;

const isTokenExpired = () => {
  const expiry = _kc.tokenParsed?.exp;
  return expiry * 1000 < new Date().getTime();
};

const updateToken = (minValidity = 5) => {
  return _kc
    .updateToken(minValidity)
    .then((refreshed) => {
      if (refreshed) {
        const payload = {
          token: _kc.token,
          idToken: _kc.idToken,
          tokenExpiresTime: _kc.tokenParsed.exp,
          tokenIssuedTime: _kc.tokenParsed.iat,
          tokenExpiryPeriod: _kc.tokenParsed.exp - _kc.tokenParsed.iat,
        };
        store.dispatch(updateRefreshToken(payload));
      }
      return refreshed;
    })
    .catch((error) => {
      console.error("Failed to refresh token", error);
      doLogin();
    });
};

const getUsername = () => _kc.tokenParsed?.preferred_username;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const KeycloakService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  getTokenParsed,
  updateToken,
  getUsername,
  hasRole,
  isTokenExpired,
};

export default KeycloakService;
