import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import packageJson from "../package.json";
import Loading from "./pages/Loading";
import store from "./store/store";
import "./index.scss";
import App from "./App";
import "./i18n";
import KeycloakService from "./pages/KeycloakService";
import CacheBuster from "react-cache-buster";
import { initOptions } from "./KeycloakConfig";
import KeycloakError from "./components/errors/KeycloakError";

const isProduction =
  import.meta.env.VITE_NODE_ENV === "production" || "teledata" || "test";

let persistor = persistStore(store);
const container = document.getElementById("root");
const root = createRoot(container);

const renderApp = () =>
  root.render(
    <React.StrictMode>
      <CacheBuster
        currentVersion={packageJson.version}
        isEnabled={isProduction}
        isVerboseMode={false}
        loadingComponent={<Loading />}
        metaFileDirectory={"."}
      >
        <Provider store={store}>
          <BrowserRouter basename="/">
            <PersistGate loading={null} persistor={persistor}>
              <App />
            </PersistGate>
          </BrowserRouter>
        </Provider>
      </CacheBuster>
    </React.StrictMode>
  );

const initApp = () => {
  KeycloakService.initKeycloak((error) => {
    if (error) {
      root.render(
        <React.StrictMode>
          <KeycloakError error={error} />
        </React.StrictMode>
      );
    } else {
      renderApp();
    }
  });
};

initApp();
